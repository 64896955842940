import { HttpHeaders, HttpParams } from '@angular/common/http';
import { WlcmQueryFilter, WlcmQueryFilters } from '@wlcm/common';

export class HttpUtils {
  static skipSuccessMessageHeader: string = 'skip_success_message';

  static skipSuccessMessage(headers: HttpHeaders = new HttpHeaders()): HttpHeaders {
    return headers.set(this.skipSuccessMessageHeader, 'true');
  }

  static filtersToHttpParams(httpParams: HttpParams, filters: WlcmQueryFilters): HttpParams {
    Object.keys(filters).forEach((key: keyof WlcmQueryFilters) => {
      const prefix: string = `filters`;

      const filter: WlcmQueryFilter = filters[key];

      Object.keys(filter).forEach((filterKey: string) => {
        let value: WlcmQueryFilter[keyof WlcmQueryFilter] = filter[filterKey as keyof WlcmQueryFilter];

        if (value instanceof Array) value = value.join(',');

        return (httpParams = httpParams.append(`${prefix}[${key}][${filterKey}]`, value as string));
      });
    });

    return httpParams;
  }
}
